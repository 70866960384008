var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(this.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "employee-form",
            submitAction: _vm.create,
            submitSuccessAction: _vm.createSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { width: "0", height: "0", overflow: "hidden" },
              attrs: { id: "card-reader-input-wrapper" }
            },
            [_c("input", { attrs: { id: "card-reader", type: "text" } })]
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.employee.name,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "name", $$v)
                  },
                  expression: "employee.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "surname", placeholder: _vm.$t("shared.surname") },
                model: {
                  value: _vm.employee.surname,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "surname", $$v)
                  },
                  expression: "employee.surname"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "fiscalCode",
                  placeholder: _vm.$t("shared.fiscalCode")
                },
                model: {
                  value: _vm.employee.fiscalCode,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "fiscalCode", $$v)
                  },
                  expression: "employee.fiscalCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "vatNumber",
                  placeholder: _vm.$t("shared.vatNumber")
                },
                model: {
                  value: _vm.employee.vatNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "vatNumber", $$v)
                  },
                  expression: "employee.vatNumber"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "phoneNumber",
                  placeholder: _vm.$t("shared.phoneNumber")
                },
                model: {
                  value: _vm.employee.phoneNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "phoneNumber", $$v)
                  },
                  expression: "employee.phoneNumber"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "mobileNumber",
                  placeholder: _vm.$t("shared.mobileNumber")
                },
                model: {
                  value: _vm.employee.mobileNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "mobileNumber", $$v)
                  },
                  expression: "employee.mobileNumber"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "email", placeholder: _vm.$t("shared.email") },
                model: {
                  value: _vm.employee.email,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "email", $$v)
                  },
                  expression: "employee.email"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "employeeRoleId",
                  placeholder: _vm.$t("shared.role"),
                  dataSource: _vm.employeeRoles,
                  fields: { text: "name", value: "id" }
                },
                model: {
                  value: _vm.employee.employeeRoleId,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "employeeRoleId", $$v)
                  },
                  expression: "employee.employeeRoleId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-datepicker", {
                attrs: {
                  id: "employmentDate",
                  placeholder: _vm.$t("employees.employmentDate"),
                  openOnFocus: true
                },
                model: {
                  value: _vm.employee.employmentDate,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "employmentDate", $$v)
                  },
                  expression: "employee.employmentDate"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-datepicker", {
                attrs: {
                  id: "terminationDate",
                  placeholder: _vm.$t("employees.terminationDate"),
                  openOnFocus: true
                },
                model: {
                  value: _vm.employee.terminationDate,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "terminationDate", $$v)
                  },
                  expression: "employee.terminationDate"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-colorpicker", {
                attrs: {
                  id: "color",
                  label: _vm.$t("employees.appointmentColor")
                },
                model: {
                  value: _vm.employee.color,
                  callback: function($$v) {
                    _vm.$set(_vm.employee, "color", $$v)
                  },
                  expression: "employee.color"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.employeeAvatar != null && _vm.employeeAvatar != "",
                  expression: "employeeAvatar != null && employeeAvatar != ''"
                }
              ],
              staticClass: "form-group"
            },
            [
              _c("div", { staticClass: "label-text" }, [
                _vm._v(_vm._s(_vm.$t("employees.avatar.title")))
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEditAvatar,
                      expression: "!isEditAvatar"
                    }
                  ]
                },
                [
                  _c("img", {
                    ref: "avatarImage",
                    staticStyle: { width: "120px" },
                    attrs: { src: _vm.employeeAvatar, alt: "photo" }
                  }),
                  _c(
                    "div",
                    { staticClass: "avatar-buttons" },
                    [
                      _c(
                        "app-button",
                        {
                          attrs: {
                            type: "button",
                            cssClass: "e-secondary e-outline"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.replaceAvatar.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("employees.avatar.replace")) + " "
                          )
                        ]
                      ),
                      _c(
                        "app-button",
                        {
                          attrs: {
                            type: "button",
                            cssClass: "e-primary e-outline"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.editAvatar.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("employees.avatar.edit")) + " ")]
                      ),
                      _c(
                        "app-button",
                        {
                          attrs: { type: "button", cssClass: "e-danger" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.removeAvatar.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("employees.avatar.remove")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEditAvatar,
                      expression: "isEditAvatar"
                    }
                  ]
                },
                [
                  _c("ejs-imageeditor", {
                    ref: "avatarEditor",
                    attrs: {
                      id: "avatarEditor",
                      name: "avatarEditor",
                      height: "450px"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "avatar-buttons" },
                    [
                      _c(
                        "app-button",
                        {
                          attrs: {
                            type: "button",
                            cssClass: "e-primary e-outline"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.saveEditedAvatar.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("employees.avatar.save")) + " ")]
                      ),
                      _c(
                        "app-button",
                        {
                          attrs: {
                            type: "button",
                            cssClass: "e-secondary",
                            disabled: _vm.isFirstEditAvatar
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.cancelEditedAvatar.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("employees.avatar.cancel")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.employeeAvatar == null || _vm.employeeAvatar == "",
                  expression: "employeeAvatar == null || employeeAvatar == ''"
                }
              ],
              staticClass: "form-group",
              attrs: { id: "avatarUploaderContainer" }
            },
            [
              _c("div", { staticClass: "label-text" }, [
                _vm._v(_vm._s(_vm.$t("employees.avatar.title")))
              ]),
              _c("ejs-uploader", {
                ref: "avatarUploader",
                attrs: {
                  id: "avatarUploader",
                  name: "avatarUploader",
                  allowedExtensions: ".jpg,.jpeg,.png,.tif,.tiff",
                  maxFileSize: "5000000",
                  multiple: false,
                  selected: _vm.avatarSelected
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.employee.contractFileName == null ||
                    _vm.employee.contractFileName == "",
                  expression:
                    "employee.contractFileName == null || employee.contractFileName == ''"
                }
              ],
              staticClass: "form-group"
            },
            [
              _c("div", { staticClass: "label-text" }, [
                _vm._v(_vm._s(_vm.$t("employees.contract.title")))
              ]),
              _c("ejs-uploader", {
                ref: "contractUploader",
                attrs: {
                  id: "contractUploader",
                  name: "contractUploader",
                  allowedExtensions: ".pdf",
                  maxFileSize: "5000000",
                  multiple: false,
                  selected: _vm.contractSelected
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("h4", { staticStyle: { margin: "12px 0 6px" } }, [
                _vm._v(_vm._s(_vm.$t("services.service")))
              ]),
              _c(
                "ejs-grid",
                {
                  ref: "employeeServicesGrid",
                  attrs: {
                    allowSorting: true,
                    dataSource: _vm.employee.services,
                    editSettings: _vm.servicesGrid.editSettings,
                    sortSettings: _vm.servicesGrid.sortOptions,
                    toolbar: _vm.servicesGrid.toolbar,
                    actionBegin: _vm.servicesGridActionBegin,
                    actionComplete: _vm.servicesGridActionComplete
                  }
                },
                [
                  _c(
                    "e-columns",
                    [
                      _c("e-column", {
                        attrs: {
                          field: "officeId",
                          width: "0",
                          validationRules:
                            _vm.servicesGrid.validationOptions.rules.officeId,
                          type: "number",
                          visible: _vm.hasMultipleOffices
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "officeName",
                          headerText: _vm.$t("offices.office"),
                          width: "200",
                          visible: _vm.hasMultipleOffices
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "serviceId",
                          width: "0",
                          validationRules:
                            _vm.servicesGrid.validationOptions.rules.serviceId,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "serviceName",
                          headerText: _vm.$t("services.service"),
                          width: "200"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "hourlyCost",
                          headerText: _vm.$t("employees.hourlyCost"),
                          width: "200",
                          validationRules:
                            _vm.servicesGrid.validationOptions.rules.hourlyCost,
                          type: "number",
                          format: "C2"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          width: "90",
                          commands: _vm.servicesGrid.commands
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("h4", { staticStyle: { margin: "12px 0 6px" } }, [
                _vm._v(_vm._s(_vm.$t("calendarTypes.calendarTypes")))
              ]),
              _c(
                "ejs-grid",
                {
                  attrs: {
                    allowSorting: true,
                    dataSource: _vm.employee.calendarTypes,
                    editSettings: _vm.calendarTypesGrid.editSettings,
                    sortSettings: _vm.calendarTypesGrid.sortOptions,
                    toolbar: _vm.calendarTypesGrid.toolbar,
                    actionBegin: _vm.calendarTypesGridActionBegin,
                    actionComplete: _vm.calendarTypesGridActionComplete
                  }
                },
                [
                  _c(
                    "e-columns",
                    [
                      _c("e-column", {
                        attrs: {
                          field: "officeId",
                          width: "0",
                          validationRules:
                            _vm.calendarTypesGrid.validationOptions.rules
                              .officeId,
                          type: "number",
                          visible: _vm.hasMultipleOffices
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "officeName",
                          headerText: _vm.$t("offices.office"),
                          width: "200",
                          visible: _vm.hasMultipleOffices
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "calendarTypeId",
                          width: "0",
                          validationRules:
                            _vm.calendarTypesGrid.validationOptions.rules
                              .calendarTypeId,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "calendarTypeName",
                          headerText: _vm.$t("calendarTypes.calendarType"),
                          width: "200"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "visibility",
                          width: "0",
                          validationRules:
                            _vm.calendarTypesGrid.validationOptions.rules
                              .visibility,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "visibilityName",
                          headerText: _vm.$t(
                            "calendarTypes.visibility.visibility"
                          ),
                          width: "100"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          width: "90",
                          commands: _vm.calendarTypesGrid.commands
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.create",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      ),
      _c("canvas", { attrs: { id: "canvasTarget" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }